"use client"

import { ReactNode, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { cn, defaultTransition } from "@/lib/utils";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselApi
} from "@/components/ui/carousel"


export default function BeerCarousel({ children, beers }: { children: ReactNode[], beers: string[][] }) {
    const [api, setApi] = useState<CarouselApi>()
    const [current, setCurrent] = useState(0)


    function prev() {
        const prevIndex = (current === 0) ? children.length - 1 : current - 1
        setCurrent(prevIndex)
    }

    function next() {
        const nextIndex = (current === children.length - 1) ? 0 : current + 1
        setCurrent(nextIndex)
    }


    const slideVariants = {
        hiddenLeft: {
            scale: 0.6,
            opacity: 0.6,
        },
        hiddenRight: {
            // position: "absolute",
            scale: 0.6,
            opacity: 0.6
        },
        visible: {
            // position: "static",
            scale: 1,
            opacity: 1,
            transition: defaultTransition
        },
        // exit: {
        //     opacity: 0,
        //     scale: 0.8,
        //     transition: {
        //         duration: 0.5,
        //     },
        // },
    };

    useEffect(() => {
        if (!api) {
            return
        }
        api.scrollTo(current)
    }, [current])

    useEffect(() => {
        if (!api) {
            return
        }

        // setCount(api.scrollSnapList().length)
        setCurrent(api.selectedScrollSnap())

        api.on("select", () => {
            setCurrent(api.selectedScrollSnap())
        })
    }, [api])

    if (children.length < 1) {
        return
    }
    return (
        <>
            {/* <div className="flex justify-center md:px-16 mt-10 relative max-w-6xl mx-auto items-center" */}
            {/* > */}
            <Carousel
                opts={{
                    duration: 9,
                    loop: true,
                }}
                setApi={setApi}
                className="w-full"
            >

                <CarouselContent

                    className="w-full h-full"
                >
                    {children.map((child, i) => (
                        <CarouselItem key={i} className="w-full">
                            <motion.div
                                className="mx-auto max-w-6xl"
                                key={i}
                                variants={slideVariants}
                                initial={i === current ? "visible" :
                                    ((i > current || current === 0) ?
                                        "hiddenRight" : "hiddenLeft"
                                    )
                                }

                                animate={i === current ? "visible" :
                                    ((i > current || current === 0) ?
                                        "hiddenRight" : "hiddenLeft"
                                    )
                                }


                                exit={i === current ? "visible" :
                                    ((i > current || current === 0) ?
                                        "hiddenRight" : "hiddenLeft"
                                    )
                                }
                            >

                                {child}
                            </motion.div>
                        </CarouselItem>
                    ))}
                </CarouselContent>


                <Arrow inverted={true} onClick={prev}
                    className="absolute left-0 top-[70%] lg:top-[50%] xl:left-[calc(50%-40rem)]"
                />
                <Arrow onClick={next}
                    className="absolute right-0 top-[70%] lg:top-[50%] xl:right-[calc(50%-40rem)]"
                />
                {/* <CarouselPrevious /> */}
                {/* <CarouselNext /> */}
            </Carousel>
            <div className="flex justify-center mt-4">
                {beers.map(([name, color], i) => (
                    <button
                        key={i}
                        aria-label={name}
                        className="p-4 md:p-8 lg:p-10"
                        onClick={() => {
                            setCurrent(i)
                        }}
                    >
                        <motion.div
                            className="w-4 h-4 rounded-full"
                            animate={(i === current) ? {
                                scale: 2
                            } : {}}
                            style={{
                                backgroundColor: color
                            }}
                        />
                    </button>
                ))}
            </div>
        </>
    )
}

function Arrow({ inverted, onClick, className }: { inverted?: boolean, onClick: () => void, className?: string }) {
    return (
        <button
            aria-label={inverted ? "birra precedente" : "birra successiva"}
            onClick={onClick}
            className={cn(
                "p-5 cursor-pointer z-10",
                // inverted ?
                //     "pl-4"
                //     :
                //     "pr-4",
                "sm:p-10",
                // "w-10 h-5",
                className
            )}>
            <svg className={cn(inverted ? "rotate-180" : "", "w-5 h-5 sm:w-10 sm:h-10")} viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.0607 13.0607C39.6464 12.4749 39.6464 11.5251 39.0607 10.9393L29.5147 1.3934C28.9289 0.807611 27.9792 0.807611 27.3934 1.3934C26.8076 1.97919 26.8076 2.92893 27.3934 3.51472L35.8787 12L27.3934 20.4853C26.8076 21.0711 26.8076 22.0208 27.3934 22.6066C27.9792 23.1924 28.9289 23.1924 29.5147 22.6066L39.0607 13.0607ZM0 13.5L38 13.5V10.5L0 10.5L0 13.5Z" fill="#886B34" />
            </svg>
        </button>
    )
}
